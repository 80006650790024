import { NgModule } from '@angular/core';

/* Modules */
import { BrowserModule } from '@angular/platform-browser';
import { LocalizationModule } from './translation/localization.module';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

/* Imports */
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { provideUserIdleConfig } from 'angular-user-idle';
import { environment } from 'src/environments/environment';

/* Guards */
import { LocalizationGuard } from './translation/localization.guard';

/* Components */
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './common-components/page-not-found/page-not-found.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LocalizationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsID),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [LocalizationGuard, provideNgxMask(), provideUserIdleConfig(environment.sessionWarningConfig),],
  bootstrap: [AppComponent],
  exports: [TranslateModule, LocalizationModule],
})
export class AppModule { }