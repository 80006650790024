<header class="ontario-header">
    <div class='ontario-row'>
        <div class="ontario-header__logo-container ontario-columns">
            <a href="https://www.ontario.ca/page/government-ontario" target="_blank">
                <img class="ontario-show-for-medium" src="assets/ontario-design-system/logos/ontario-logo--desktop.svg"
                    width="180" height="46" alt="ontario logo" />
                <img class="ontario-show-for-small-only"
                    src="assets/ontario-design-system/logos/ontario-logo--mobile.svg" width="45" height="46"
                    alt="ontario logo" />
            </a>
        </div>
    </div>
</header>

<div class='ontario-row'>
    <div class="body">
        <img src="../../../assets/images/404-error-logo.svg" alt="" class="image">
        <div class="error-text">
            <h2 class="ontario-h1">404 Error | Erreur 404</h2>
            <p>Sorry, but we're unable to find this page. <a
                    href='https://www.ontario.ca/page/submit-tow-and-vehicle-storage-rates' target="_blank">Go to
                    tow and vehicle storage page | Ontario.ca</a> </p>
            <p>Désolé mais, nous n'arrivons pas à trouver cette page. <a
                    href='https://www.ontario.ca/fr/page/soumettez-vos-tarifs-de-remorquage-et-dentreposage-de-vehicules'
                    target="_blank">Aller à | Ontario.ca</a> </p>
        </div>
    </div>
</div>


<footer class='ontario-footer ontario-footer--default'>
    <div class='ontario-row'>
        <div class='ontario-columns ontario-small-12 ontario-medium-7 '>
            <ul class='ontario-footer__links-container ontario-footer__links-container--inline'>
                <li><a href="https://www.ontario.ca/page/accessibility" target='_blank' class='ontario-footer__link'>{{
                        'common.footer.Accessibility' | translate }}</a></li>
                <li><a href="https://www.ontario.ca/page/privacy-statement" target='_blank'
                        class='ontario-footer__link'>{{ 'common.footer.Privacy' | translate }}</a></li>
                <li><a href="https://www.ontario.ca/page/know-your-rights-when-getting-tow#section-6" target='_blank'
                        class='ontario-footer__link'>{{ 'common.footer.Contact us' | translate
                        }}</a>
                </li>
            </ul>
            <div class=' ontario-footer__copyright'>
                <a href="https://www.ontario.ca/page/copyright-information" target='_blank'
                    class='ontario-footer__link'>{{ 'common.footer.© King Printer for Ontario, 2024' | translate
                    }}</a>
            </div>
        </div>
    </div>
</footer>