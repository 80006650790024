import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocalizationGuard } from './translation/localization.guard';
import { PageNotFoundComponent } from './common-components/page-not-found/page-not-found.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: `${environment.rootRoute}`,
    pathMatch: 'full'
  },
  {
    path: `${environment.rootRoute}`,
    loadChildren: () => import('./tsoa/publish-rates/tsoa-publish-rates.module').then(m => m.TsoaPublishRatesModule),
    canActivate: [LocalizationGuard],
  },
  {
    path: `fr/${environment.rootRoute}`,
    loadChildren: () => import('./tsoa/publish-rates/tsoa-publish-rates.module').then(m => m.TsoaPublishRatesModule),
    canActivate: [LocalizationGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes , { scrollPositionRestoration: 'enabled' })], // for scrolling to top of page on route change)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
